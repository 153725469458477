const getCommonEnglish = (auto24Domain: string) => ({
    and: 'and',
    buy: 'Buy',
    sell: 'Sell',
    send: 'Send',
    finance: 'Finance',
    about: 'About',
    blogs: 'Blogs',
    blog: 'Blog',
    callUsAt: 'Call us at',
    signIn: 'Sign In',
    signUp: 'Sign Up',
    or: 'or',
    insure: 'Insure',
    enteremailAddress: 'Enter Email Address',
    enterPassword: 'Enter Password',
    rememberMe: 'Remember Me',
    forgotPassword: 'Forgot Password',
    carsAvailable: 'Cars Available Now',
    carAvailable: 'Car Available Now',
    cancel: 'Cancel',
    enterFullName: 'Enter Full Name',
    byClickingXButton: 'By clicking {{name}} Button, you agree to our',
    termOfUse: 'Terms of Use',
    privacyPolicy: 'Privacy Policy',
    alreadyhaveanAccount: 'Already have an account?',
    howItWorks: 'How It Works',
    howtoBuy: 'How to Buy',
    howtoSell: 'How to Sell',
    exploreBlogs: 'Explore Blogs',
    car: 'Car',
    search: 'Search',
    Certified: 'Certified',
    Inspected: 'Inspected',
    petrol: 'Petrol',
    automatic: 'Automatic',
    pleaseprovideusyourdetails: 'Please provide us your details',
    pleaseprovideusyourcardetails: 'Please provide us your car details',
    desiredPleaseprovideusyourcardetails:
        'Please provide us your desired car details, select at least one.',
    phonenumberisnotvalid: 'Phone number is not valid',
    internalError: 'Internal error. Please try again or contact us.',
    knowaboutyourcarbetterandgetevaluation:
        'Know about your car better and get valuation for a better deal.',
    cardetailssendSuccessfully: 'Car details sent sucessfully',
    thanyouforprovidingusdetail:
        'Thank you for provding us the details. We will give you a call back soon.',
    selectbrand: 'Select a brand',
    selectcountry: 'Select country',
    enterMileage: 'Enter Mileage',
    minMileage: 'Minimum Mileage',
    maxMileage: 'Maximum Mileage',
    selectmodel: 'Select a model',
    selectYear: 'Select Year',
    selectorenterbrand: 'Select a brand',
    selectorentermodel: 'Select a model',
    selectorenterbodyType: 'Select or enter a body type',
    brand: 'Brand',
    model: 'Model',
    modelYear: 'Model Year',
    selectFuelType: 'Select Fuel Type',
    selectACondition: 'Select a Condition',
    phoneNumber: 'Phone Number',
    loanTitle: 'We Provide You The Best Offer, get 10% Interest',
    loanHappyCusSubtitle:
        // 'We do care about our customers and their happiness which encourage us to provide more services',
        'You can trust us like these happy clients! Check our reviews!',
    loanButton: 'Calculate My Loan',
    calculateButton: 'Calculate',
    blogTitle: 'Have A Look Before Buying Car',
    blogSubtitle:
        'These are the thing that you must know before buying any kind of car',
    viewAllCars: 'View All Cars',
    viewAllResultsFor: 'View All Results For',
    availableNowOn: 'Available Now On',
    account: 'Account',
    myAccount: 'My Account',
    ourBrands: 'Our Brands',
    contactUs: 'Contact us',
    contactInformation: 'Contact Information',
    aboutUs: 'About us',
    addressLine: 'Address Line',
    contact: 'Contact',
    allcarshavebeenthoroughlyinspectedandreconditionedHowweguaranteecarquality:
        ' All our cars have been inspected and reconditioned meticulously by our experts',
    imInterested: "I'm interested",
    buyOnline: 'Buy Online',
    downPayment: 'Down Payment',
    hotDeal: 'Hot deal',
    lowPrice: 'Low price',
    likeNew: 'Like new',
    moneybackSubtitle: 'You don’t like the car? We refund you',
    warrentyTitle: '6 months warranty',
    warrentySubtitle:
        'Extendable to 12 months on engine, gearbox and powertrain',
    inspectionPointsSubtitle:
        'Inspected by our team of auto experts on all angles',
    roadSideAssistanceTitle: '1 year assistance',
    roadSideAssistanceSubtitle:
        'Roadside assistance included on all our vehicles, for one year',
    deliveryTitle: 'Home delivery',
    deliverySubtitle: 'We can deliver your car anywhere in Morocco',
    xDeliverySubtitle: 'We can deliver your car anywhere in {{country}}',
    Summary: 'Summary',
    engine: 'Engine',
    enginePower: 'Engine Power',
    fuelType: 'Fuel Type',
    bodyType: 'Body Type',
    numberofkeys: 'Number of keys',
    exteriorColor: 'Exterior Color',
    interiorColor: 'Interior Color',
    engineSize: 'Engine size',
    fiscalPower: 'Fiscal power',
    specifications: 'Specifications',
    lengthWidthHeight: 'Length,Width & Height',
    weight: 'weight',
    emissionCO2: 'Emission CO2',
    fuelConsumption: 'Fuel Consumption',
    enginepower: 'Engine Power',
    fuelTankCapacity: 'Fuel Tank Capacity',
    performanceNEconomy: 'Performance & Economy',
    features: 'Features',
    weightNDimensions: 'Weight & Dimensions',
    showMoreFeatures: 'Show More Features',
    showLess: 'Show Less',
    serviceHistory: 'Service History',
    whyAUTO24: 'Why AUTO24',
    whyChooseAuto: 'Why Choose AUTO24?',
    serviceCenter: 'Service Center',
    Kilometers: 'Km',
    lookingForaCarQtellUs: 'Looking For a Car? Tell Us!',
    sellPageTitle: 'Sell Your Car With Two Easy Steps',
    sellPageSubtitle:
        'You can provide details about your car and we will call you back for futher deals.',
    findCarPricePageTitle: 'Find The Price of Your Car',
    findCarPricePageSubtitle:
        'You can provide details about your car and we will call you back for futher deals.',
    whyFinanceYourCarwithAuto24: 'Why Finance Your Car With AUTO24',
    financeSubtitle:
        'We provide you the best financing offers with low interest and downpayments',
    competitiveRates: 'Competitive Rates',
    competitiveDesc: 'APR from 5% (indicative)',
    competitiveDescMA: 'Possibility of free credit under conditions',
    flexiableDuration: 'Flexible Durations',
    flexiableDes: 'Pay for your car in 12 to 72 monthly installments',
    Percent100OnlineApproach: '100% Online Approach',
    Percent100OnlineApproachDesc: 'Sign all you documents at home',
    loanCalculatorTitle: 'Car Loan Calculator',
    loadCalculatorSubtitle:
        'Monthly interest rate may differ as we partner with different finance institutions.',
    interestRate: 'Interest Rate',
    years: 'years',
    ourFinancingPartners: 'Our Finance partner',
    ourInsurePartners: 'Our insurance partner',
    ourPartners: 'Our Partners',
    copyrightfooter: 'Copyright © Africar Group Pty Ltd - All rights reserved',
    insureSubtitle: 'Enjoy the best insurance policy at the best price',
    aboutFinanceSub: 'Here are some of our financing partners',
    selectMileageRange: 'Select Mileage Range',
    selectTransmissionType: 'Transmission Type',
    backToHome: 'Back to Home',
    getACallBack: 'Get a Callback',
    personalDetails: 'Personal Details',
    carDetails: 'Car Details',
    enterCarDetails: 'Enter Car Details',
    ourBrandsSubtitle:
        'We are associated with the top brand car which have won many awards for the best performance',
    ourTeam: 'Our Team',
    findCarPrice: 'Pricing Tool',
    teamMembers: 'Team Members',
    ourTeamSubtitle: 'Know us better through our teams thoughts',
    latestPosts: 'Latest Posts',
    lastestPostSubtitle: 'Here are latest blogs from different users',
    featuredPosts: 'Featured Posts',
    featuredPostSubtitle: 'Here are featured blogs from different users',
    newsLetterTitle: 'Never Miss a Story',
    newsLetterSubtitle:
        'Subscribe to AUTO24 Blog to stay updated about everything Cars.',
    buySellTitle: 'Are you Ready ?',
    buySellSubtitle:
        'Whether it’s selling your used car or buying your next ride, we can do it all.',
    sellYourCar: 'Sell Your Car',
    buyaCar: 'Buy a Car',
    footerInfo: `${auto24Domain} is a subsidiary of Africar Group Pty Ltd, registered in Ivory Coast under the IDU CI-2022-0027564 O.`,
    FAQ: 'FAQ',
    buyCars: 'Buy Cars',
    sellCars: 'Sell Cars',
    categories: 'Categories',
    topStories: 'Top Stories',
    pleaseInputYourPassword: 'Please input your password!',
    donthaveanaccount: "Don't have an account",
    resetPassword: 'Reset Password',
    editProfile: 'Edit Profile',
    createNewPassword: 'Create New Password',
    passwordresetsuccessfully: 'Password reset successfully',
    pleaseInputYourName: 'Please input your Name!',
    pleaseInputYourEmail: 'Please input your Email Address!',
    pleaseInputValidEmail: 'Please input a valid Email Address!',
    pleaseInputYourPhoneNumber: 'Please input your Phone number!',
    maximumXdigitsisallowed: 'Maximum {{X}} digits is allowed',
    minimumXdigit: 'Minimum {{X}} digits is allowed',
    passwordisRequired: 'Password is required!',
    minimum8chars: 'Minimum 8 characters are required',
    usersuccessfullyregistered: 'User successfully registered !',
    shortBio: 'Short Bio',
    Save: 'Save',
    doyouwantDeleteAccount: 'Do you want to permanently delete your account?',
    delete: 'Delete',
    saveChanges: 'Save Changes?',
    youcanstillchangetheseinformation: 'You can still change these information',
    changessavedsuccessfully: 'Changes saved successfully',
    yourchangessavedsuccessfully: 'Your changes have been saved successfully',
    deleteyourAccount: 'Delete your account ?',
    deleteAccountsubtitile:
        'Once you delete your account your all data will be lost and you cant  get access to your account.',
    accountdeletedsuccessfully: 'Account deleted successfully',
    youcanstillexpolrecarwithouthavinganaccount:
        'You can still explore cars without having an account.',
    logout: 'Logout',
    xInspectionPoints: '{{number}} inspection points',
    xlistingPageResultTitle: '{{number}} Cars Found',
    sortBy: 'Sort By:',
    lowestPriceFirst: 'Lowest Price First',
    highestMileageFirst: 'Highest Mileage First',
    lowestMileageFirst: 'Lowest Mileage First',
    price: 'price',
    mileage: 'Mileage',
    selectBodyType: 'Select Body Type',
    filter: 'Filters',
    moreFilters: 'More Filters',
    color: 'Color',
    transmission: 'Transmission',
    highestPriceFirst: 'Highest Price First',
    provideUsYourDetail: 'Provide Us Your Detail',
    interestcartitle: 'Are You Willing to Buy This Car?',
    interestcarsubtitle:
        'Choose one option, the others will continue to be available in your purchase process',
    bookThisCar: 'Book this car',
    bookThisCarSubtitle:
        'Click here to\nsend us a payment of 3000 DH, we block the car for 1 week, just for you',
    scheduleAVisit: 'Schedule a visit',
    scheduleAVisitSubtitle:
        'Visit our store to have a look at your desired car. It still will be available to other buyers',
    popularblog: 'Popular Blogs',
    popularblogSub: 'Here are Popular blogs from different users',
    category: 'Category',
    carOverview: 'Car Overview',
    Detailssentsuccessfully: 'Details sent successfully',
    DetailChangeSubtitle:
        'Thank you for providing us your details. We will give you a call back soon.',
    continue: 'Continue',
    carnotfoundSubtitle:
        'No car is available for selected criteria. Help us with your details and we will help you find the right car!',
    logoutModelTitle: 'Are you sure you want to Logout?',
    french: 'French',
    QuickLinks: 'Quick Links',
    similarResults: 'Similar Results ',
    copyrightInfo: `${auto24Domain} is a subsidiary of Africar Group Pty Ltd, registered under the unique identification number CI-2022-0027564 O in the Republic of Cote d'Ivoire`,
    recentlyAdded: 'Recently Added',
    recentlyAddedSubtitle:
        'Here you can see different cars which has been recently added',
    newCarList: 'New Cars',
    newCarListSubtitle: 'Here you can see different new cars',
    electricCarList: 'Electric Cars',
    electricCarListSubtitle: 'Here you can see different electric cars',
    importedCarList: 'Imported Cars',
    importedCarListSubtitle: 'Here you can see different imported cars',
    cheapestCarList: 'Cars Under {{X}}',
    cheapestCarListSubtitle:
        'Here you can see different cheap cars under {{X}}',
    hereyoucanseedifferentsimilarcars:
        'Here you can see different similar cars',
    carNotFound: 'Car Not Found',
    whatsappUrlText: 'Hello! I am interested in this car',
    latestService: 'Latest Service',
    resetSuccessMsg:
        'Now you can sign in with your new password or you can still explore cars',
    bothPasswordMustMatch: 'Both passwords must match',
    mustBeAtleast8Characters: 'Must be at least 8 characters',
    sendResetLink: 'Send Reset Link',
    returnTo: 'Return to',
    enterEmailToGetResetLinkInfo:
        "Enter the email associated with your account and we'll send an email with instruction to reset your password",
    resetPasswordModalTitle: 'Are you sure you want reset your password?',
    yourPasswordChangedSuccessfully:
        'Your password has been changed successfully',
    confirmPassword: 'Confirm Password',
    carViewsXTimes: 'Viewed {{number}} times today',
    shortDescription: 'Short Description',
    carImages: 'Car Images',
    uploadImage: 'Upload Image',
    browse: 'Browse',
    listAnother: 'List Another',
    listCarRequestSentSucessfully: 'List Car Request Sent Successfully',
    basicDetails: 'Basic Details',
    manual: 'Manual',
    postYouHaveLiked: 'Post you have liked',
    enterOldPassword: 'Enter Old Password',
    enterNewPassword: 'New Password',
    enterPrice: 'Enter your Price',
    confirmNewPassword: 'Confirm New Password',
    passwordChangeSuccessfully: 'Password changed successfully',
    verificationTitle:
        'We have sent you a verification link to you email address',
    viewLink: 'View Link',
    resendingLink: 'Resend Link',
    tokenExpired: 'Your token is expired please create new one',
    tokenExpiredTitle: 'Account Verification Link Expired',
    callBackSubtitle:
        'Enter your name and phone number we’ll get back to you soon',
    provideUsYourDetails: 'Provide us Your Detail',
    diesel: 'Diesel',
    other: 'Other',
    showCars: 'Show Cars',
    accountVerifiedSuccessfully: 'Account verified successfully',
    accountVerifiedSuccessfullySubtitle:
        'Your account has been verified successfully. Do you want to login?',
    engineSound: 'Engine Sound',
    showAllImage: 'Show All Images',
    ourBenefits: 'Our Benefits',
    inspectionPoints: '100 inspection points by our car experts',
    warranties: '6 months warranty (engine,gearbox and axle)',
    maintenances: `1 year maintenance package with ${auto24Domain}`,
    assistance: '12 months towing and breakdown assistance',
    multiCurrency: 'Multi-currency payment DH, €, $, £',
    moneyBack: '5 days money back guarantee',
    financing: 'Financing ',
    dragAndDrop: 'Drag & drop image or',
    imageFormat: 'Supported formats: JPEG, PNG',
    sellNow: 'Sell Now',
    sellCar: 'Sell Car',
    confirm: 'Confirm',
    didntFindQuestion: 'Didn’t find the question you’re looking for?',
    yourMessage: 'Your Message',
    sendMessage: 'Send Message',
    loanTenure: 'Loan Tenure',
    selectLoanTenure: 'Select Loan Tenure',
    interestInput: 'Enter amount between ({{X}} and {{Y}})',
    months: 'Months',
    from: 'From',
    upto: 'Upto',
    rateOfInterest: 'Rate of Interest',
    tenure: 'Tenure',
    monthlyEmi: 'Monthly EMI',
    loadMore: 'Load More',
    contactSubtitle: 'Have any queries ? You are free to message us.',
    inspector: 'Inspector',
    inspectorSubtitle: 'Ask for an inspector',
    insurance: 'Insurance',
    insuranceSubtitle: 'Ask for best insurance',
    financeSubtitles: 'Ask for best finance',
    diagnostic: 'Diagnostic',
    minPrice: 'Min Price',
    maxPrice: 'Max Price',
    minDistance: 'Min Distance',
    maxDistance: 'Max Distance',
    min: 'Min',
    max: 'Max',
    diagonosticSubtitle: 'Ask for diagnostic',
    VINVerify: 'VIN Verify',
    VINVerifySubtitle: 'Ask for quick VIN verify',
    learnMore: 'Learn More',
    speedRegular: 'Speed Regulator',
    airCondition: 'Air Condition',
    sequential: 'Sequential',
    CDPlayer: 'CD Player',
    reversingCamera: 'Reversing Camera',
    reversingRadar: 'Reversing Radar',
    GPSNavigation: 'GPS Navigation',
    parkAssist: 'Park Assist',
    startAndStop: 'Start and Stop',
    airbag: 'Airbag',
    ABS: 'ABS',
    computer: 'Computer',
    rims: 'Rims',
    sunroof: 'Sun Roof',
    electricMirrors: 'Electric mirrors',
    electricWindows: 'Electric windows',
    dateFirstUse: 'Date first use',
    seats: 'Seats',
    certifiedUsedCars: 'Certified Used Cars',
    certifiedUsedCarsSubtitle: 'Buy used cars with trust and transparency',
    inpectionsPoints: '100 Inspection Points',
    inpectionsPointsSubtitle: 'All our cars have been checked thoroughly',
    refundPolicy: '5 Days Refund Policy',
    refundPolicySubtitle: 'You don’t like it, get refunded!',
    warrentySubtitles: 'Engine, gearbox and powertrain, 6 months warranted',
    excellentCondition: 'Excellent condition',
    goodCondition: 'Good condition',
    averageCondition: 'Average condition',
    damagedCar: 'Damaged car',
    lessThan10: 'Less than 10 000 Km',
    betweenXAndY: 'Between {{X}} and {{Y}} km',
    moreThan100: 'More than 100 000 Km',
    serviceTitle: 'Buying or Selling ? We Can Help You',
    serviceSubtitle:
        'Explore our services and make your car buying / selling experience seamless ',
    verifyAccount: 'Verify Account',
    verifyNumber: 'Verify Number',
    otpVerificationSubtitle:
        'Enter the 5 digit code sent to {{code}} {{number}}',
    otpVerificationEmail: 'Enter the 5 digit code sent to {{email}}',
    didntGetTheCode: 'Didn’t get the code?',
    resendCode: 'Re-send code',
    bluetooth: 'Bluetooth',
    home: 'Home',
    lookingForBestInsurance: 'Looking For The Best Insurance?',
    ourCustomerRepresentativesWillGetBackInTouchWithYou:
        'Our customer representatives will get back in touch with you.',
    upload: 'Upload',
    profileUpload: 'Upload New Picture',
    remove: 'Remove',
    xIsrequired: '{{name}} is required',
    number: 'Number',
    backToCarDetail: 'Back To Detail Page',
    backToTheCarDescription: 'Back to the car description',
    backToTheCarListing: 'Back To Car Listing',
    phoneVerifiedSuccesfully: 'Phone Verified successfully',
    phoneVerifiedSubtitle:
        'Your phone number has been verified successfully. Do you want to login',
    signWithPhone: 'Sign in with Phone',
    signWithEmail: 'Sign in with Email',
    newPriceFrom: 'New Car Price From',
    theySpeakAboutUs: 'They speak about us',
    theySpeakAboutUsSubTitle:
        'A selection of international press leaders speaking about AUTO24',
    carLoanSubTitle: 'Estimated Monthly Repayment',
    carLoanTotalRepayment: 'Total Repayment Amount',
    forgotTokenExpired: 'Password Reset Link is Expired',
    discover: 'Discover the car',
    paperWork: 'Vehicles paperwork are up to date and verified',
    verifyhistory: 'We verify history of all the vehicles we buy',
    buyBack: 'We buy back your old car',
    additionalPhotos: 'Additional Photos',
    technicalVisit: 'Technical visit certificate',
    keysPhotos: 'Keys photo',
    seeKeyPhotos: 'See the photo',
    pleaseTryAfterSomeTime: 'Please try after some time',
    favourites: 'Favourites',
    electric: 'Electric',
    autonomy: 'Autonomy',
    productionYear: 'Production Year',
    payWithOtherCurrencytitle: 'Pay in an other currency with AUTO24',
    payWithOtherCurrencySubtitle:
        'With AUTO24, you can pay on one of our international bank accounts in any of the currencies below. Here is the price you would need to transfer.',
    convertedPrice: 'Converted Price',
    favouritesNotFoundSubtitle:
        'You haven’t added any cars to favourites. Explore cars and add your favourites',
    noFavouritesFound: 'No Favourites Found',
    frontCrankOilSeal: 'Front crank oil seal',
    engineOverHaul: 'Engine overhaul',
    rearCrankOilSeal: 'Rear crank oil seal',
    engineTuneUp: 'Engine tune up',
    engineMount: 'Engine Mount',
    oilPanGasket: 'Oil Pan Gasket',
    valveCoverGasket: 'Valve cover gasket',
    oilFilterBracketOring: 'Oil filter bracket oring',
    intakeManifoldGasket: 'Intake Manifold Gasket',
    waterLeak: 'Water leak',
    radiator: 'Radiator',
    engineTimingCoverGasket: 'Engine Timing Cover Gasket',
    waterPump: 'Water Pump',
    belts: 'Belts',
    fuelFilter: 'Fuel Filter',
    airFilter: 'Air Filter',
    underChassis: 'Under Chassis',
    tieRod: 'Tie Rod',
    ballJointLandR: 'Ball Joint L&R',
    idlerArm: 'idler Arm',
    pitmanArm: 'Pitman Arm',
    steeringPump: 'Steering Pump',
    steeringHoses: 'Steering Hoses',
    steeringBox: 'Steering Box',
    steeringRackAndPinion: 'Steering Rack & Pinion',
    frontShockLandR: 'Front Shock L&R',
    rearShockLandR: 'Rear Shock L&R',
    stabilizerLinkage: 'Stabilizer Linkage',
    crossJointPropellerShaft: 'Cross Joint Propeller Shaft',
    upperArmBushing: 'Upper Arm Bushing',
    rearLowerArmBushing: 'Rear Lower Arm Bushing',
    suspensionSpring: 'Suspension Spring',
    breakSystem: 'Break System',
    brakePad: 'Brake Pad',
    brakeDiscRotor: 'Brake Disc Rotor',
    wheelBearing: 'wheel Bearing',
    brakeMasterCLY: 'Brake Master CLY',
    backBooster: 'Back Booster',
    brakeWheelCall: 'Brake Wheel Call',
    handBrakeCable: 'Hand Brake Cable',
    brakeHoses: 'Brake Hoses',
    rearAxlesAndDriveShafts: 'Rear Axles & Drive Shafts',
    frontAxle: 'Front Axle',
    rearAxle: 'Rear Axle',
    axleDifference: 'Axle Difference',
    differenceFrontOrRear: 'Difference Front Or Rear',
    axleSealFrontOrRear: 'Axle Seal Front Or Rear',
    oilPumpGasket: 'Oil Pump Gasket',
    frontOilSeal: 'Front Oil Seal',
    rearOilSeal: 'Rear Oil Seal',
    transmissionMount: 'Transmission Mount',
    gearStickSouta: 'Gear Stick Souta',
    oilCollerPipe: 'Oil Cooler Pipe',
    gearPerformance: 'Gear Performance',
    liquidSystem: 'Liquid System',
    engineOil: 'Engine Oil',
    transmissionOil: 'Transmission Oil',
    brakeFluid: 'Brake Fluid',
    engineCoolant: 'Engine Coolant',
    washerFluid: 'Washer Fluid',
    electricitySystem: 'Electricity System',
    battery: 'Battery',
    alternator: 'Alternator',
    starler: 'Starler',
    lights: 'Lights',
    ac: 'A/C',
    centerLock: 'Center Lock',
    wiperBlades: 'Wiper Blades',
    troubleCode: 'Trouble Code',
    computerCods: 'Computer Cods',
    acCondensor: 'A/C Condenser',
    acRadiator: 'A/C Radiator',
    interior: 'Interior',
    seatAndBelts: 'Seats And Belts',
    interiorControlsAndSwitches: 'Interior Controls And Switches',
    sunRoofAndWindows: 'Sun Roof And Windows',
    fuelAndTemperatureGauge: 'Fuel And Temperature Gauge',
    dashboardAndGauges: 'Dashboard And Gauges',
    radioOrMusicSystem: 'Radio Or Music System',
    sRSAirbags: 'SRS Airbags',
    steeringWheelTiltOrlock: 'Steering Wheel Tilt Or Lock',
    mirrors: 'Mirrors',
    bodyInspection: 'Body Inspection',
    inspectionDate: 'Inspection Date',
    carInspectionReport: 'Car Inspection Report',
    showPicture: 'Show Picture',
    pageNotFound: 'Page Not Found',
    pageRemoved: 'This page doesn’t exist or was removed!',
    askQuestionOnWhatsapp: 'Questions? Ask us on whatsapp!',
    whatsappsPlaceholder: 'Ask anything!',
    customerSupport: 'Customer Support',
    needHelpChatWithUs: 'Need Help? Chat with us on Whatsapp',
    instaTitle: 'Follow us on Instagram',
    instaSubTitle:
        'Get updated with our latest offers, products and news instantly!',
    questionSentSuccessfully: 'Question Sent Successfully',
    questionSentSuccessfullySubtitle:
        'Your question has been sent successfully. We will get back to you for your answer',
    unSubscribeMessage:
        'You have been successfully unsubscribed from the Auto24 Blogs.',
    numberMustBeUnique: 'Number must be unique',
    pleaseEnterFullName: 'Please Enter Full Name',
    saving: 'Saving',
    sending: 'Sending',
    signingIn: 'Signing In',
    signingUp: 'Signing Up',
    loggingout: 'Logging out',
    deleting: 'Deleting',
    querySubmittedSuccessfully: 'Query submitted successfully',
    somethingWentWrong: 'Something went wrong!',
    removedFromWishlist: 'Removed from wishlist !!',
    addedToWishlist: 'Added to wishlist !!',
    subscribedSuccessfully: 'Subscribed successfully',
    alreadySubscribed: 'Already subscribed',
    messageIsRequired: 'Message is required',
    fullName: 'Full Name',
    phoneNumberVerifiedSuccess:
        'Phone number verified successfully, Please login.',
    verifyEmail:
        'Please verify your email for Auto24, Verification link has been sent to your email',
    sold: 'Sold',
    year: 'Year {{X}} and above',
    careers: 'Careers',
    discountOff: '{{X}} de Remise',
    ev: 'Electric',
    hybrid: 'Hybrid',
    phev: 'PHEV',
    carburant: 'Carburant',
    xlistingPageResultsTitle: '{{number}} Cars Found',
    filterBy: 'Filter By:',
    new: 'New',
    used: 'Used',
    popular: 'Popular',
    makes: 'Makes',
    carTypes: 'Car Types',
    explore: 'Explore',
    followUS: 'Follow Us',
    none: 'None',
    all: 'All',
    discount: 'Discount',
    financeYourCarWith: 'Finance Your Car With',
    creditButton: 'CREDIT GRATUIT',
    loanPaymentSimulator: 'Car Loan Calculator',
    loanPaymentContent:
        'We make it easy for you to own the car you want. Let us help you secure a loan from paperwork submission to getting fast loan approval.',
    vehiclePrice: 'Vehicle price',
    noOfMonthlyPayments: 'Number of Monthly Payments',
    monthlyPaymentAmount: 'Monthly payment amount',
    // months: 'Months',
    creditRequestedAmount: 'Credit Requested Amount',
    overallCostOfCredit: 'Overall Credit',
    simulatorContribution: 'Simulator contribution',
    percentageOfContribution: 'Percentage of Contribution',
    applicationFees: 'Application fees',
    monthlyInsurance: 'Monthly insurance',
    noOfOwners: 'Number Of Owners',
    condition: 'Condition',
    maisonMere: 'Maison Mere',
    imported: 'Imported',
    tamperedOdometer: 'Tampered Odometer',
    carIssue: 'Car Issues',
    evaluateAgain: 'Evaluate Again',
    marketValue: 'Market Value',
    downloadAuto24App: 'Download the Auto24 App',
    browseMore: `Browse, buy, and sell with ease - your ultimate automotive companion`,
    saveTimeAndEffort: 'Save Time and Effort',
    saveTimeAndEffortSubtitle:
        'Let us save you valuable time as we handle everything, making selling effortless and stress-free.',
    freeNoCost: 'Free At No Cost',
    freeNoCostSubtitle: `Leave inspection, photography, transaction costs, and procedures to us - we've got you covered.`,
    competetivePrice: 'Competitive Price',
    competetivePriceSubtitle:
        'Get top price hassle-free with us; we manage all buyer communications and inquiries.',
    carTitle: `Car Details`,
    carDesc: `Provide us the information about your car`,
    personalDetail: `Personal Details`,
    personalDetailDesc: `Provide us the information about you`,
    sellCarHere: `Sell Car`,
    sellCarHereDesc: `You can get a perfect deal and we will post your car`,
    buySellUsedCar: 'Buy and sell used cars in',
    auto24Press: 'Auto24 Press',
    auto24PressDesc1:
        'Auto24 has been recognised in different media around the world.',
    auto24PressDesc2:
        'Auto24 has also been featured in conferences in Paris/France, Lisbon/Portugal, Barcelona/Spain, Sydney/Australia, London/UK, Kigali/Rwanda, Bangkok/Thailand, Abidjan/Ivory Coast, Johannesburg/South Africa, Dakar/Senegal, Nairobi/Kenya, Casablanca/Morocco…',
    mediaExposure: 'Our Media Exposure',
    mediaExposureDesc: 'Below is a selection of our media exposure.',
    press: 'Press',
    enterValidPhoneNumber: 'Enter Valid Phone Number',
    findUsGoogle: 'Find us on Google Map',
    locateUs: 'Locate Us',
    whereFindUs: 'Where to Find Us?',
    aroundLocationText: 'You can find us around these locations',
    facebook: 'Facebook',
    instagram: 'Instagram',
    tiktok: 'Tiktok',
    twitter: 'Twitter',
    youtube: 'Youtube',
    linkedin: 'LinkedIn',
    heroTitle:
        'Fast and easy way to <yellow>Buy</yellow> or <green>Sell</green> Cars',
    caRefundPolicy: 'Refund policy',
    caRefundPlicyDesc:
        'Hassle-free returns. Get a refund if you’re not satisfied.',
    caWarranty: 'Warranty',
    caWarrantyDesc: '6-month warranty. Relax, your car is covered.',
    caInspectedDesc: 'Quality assured. Certified by a team of experts.',
    login: 'Login',
    wantToSellYourCar: 'Want to sell your car?',
    findUs: 'Find Us',
    featuredCars: 'Featured Cars',
    exploreCarByBodyType: 'Explore Car By Body Type ',
    searchByBrands: 'Search By Brands',
    loanSubtitle: 'We do care about our customers and their happiness which encourage us to provide more services',
    perfectMatchYourBudget: 'Perfect Match For Your Budget',
    sellYourVehicle: 'Wish To Sell Your Vehicle?',
    sellYourVehicleSubtitle: 'We do care about our customers and their happienes which encourage us to provide more services',
    sellMyCar: 'Sell my Car',
    blogTitleMobile: 'Look Before Buying',
    blogSubtitleMobile: 'Latest reads from auto24',
    readMore: 'Read More',
    opps: 'Oops!',
    noCarSection: 'Looks like there are no listing in this segment. Would you like to request a car?',
    requestCar: 'Request Car',
    submitRequestFormSubtitle: 'Submit a request, and we’ll help you find the perfect car tailored to your needs.',
    requestCarPageTitle: 'Discover the Car You’ve Been Searching For',
    requestCarPageSubtitle: 'Let us know your preferences, and our team will work to find the ideal match for you. Submit your request today and get one step closer to driving your dream car.',
    viewAll: 'View All',
});

export default getCommonEnglish;

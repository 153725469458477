import {
    FETCH_BLOG_ERROR,
    FETCH_BLOG_LOADING,
    FETCH_BLOG_SUCCESS,
    FETCH_RECENT_BLOG_SUCCESS,
    FETCH_ONE_BLOG,
    IBlogInitailType
} from '../types/BlogListTypes';

const initialState: IBlogInitailType = {
    loading: false,
    error: false,
    blogs: [],
    featuredBlogs: [],
    latestBlogs: [],
    recentBlogs: [],
    blog: null,
    carBrands: null
};

const blogReducer = (state: IBlogInitailType = initialState, action: any) => {
    switch (action.type) {
        case FETCH_BLOG_LOADING:
            return {
                ...state,
                loading: true
            };

        case FETCH_BLOG_SUCCESS:
            let blogs = action.data.blogs,
                featuredBlogs = action.data.featuredBlogs,
                latestBlogs = action.data.latestBlogs;
            return {
                ...state,
                loading: false,
                blogs,
                featuredBlogs,
                latestBlogs
            };
        
        case FETCH_RECENT_BLOG_SUCCESS:
            return {
                ...state,
                loading: false,
                recentBlogs: action.data.blogs
            };

        case FETCH_ONE_BLOG:
            let blog = action.data;
            return {
                ...state,
                loading: false,
                blog
            };

        case FETCH_BLOG_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default blogReducer;

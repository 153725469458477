const metaXhosa = {
    homePageTitle: "AUTO24.co.za - Gesertifiseerde Gebruikte Motors in Suid-Afrika | Koop & Verkoop Plaaslik",
    homePageDesc: "Soek jy 'n gesertifiseerde gebruikte motor in Suid-Afrika? AUTO24.co.za is jou plaaslike mark vir die koop en verkoop van kwaliteit gebruikte motors.",
    homePageKeywords: "Gesertifiseerde Gebruikte Motors, Koop Motors Suid-Afrika, Verkoop Motors, Motor Mark, AUTO24 Suid-Afrika",
    homePageTitleOg: "AUTO24.co.za - Gesertifiseerde Gebruikte Motors in Suid-Afrika | Koop & Verkoop Plaaslik",
    homePageDescOg: "Soek jy 'n gesertifiseerde gebruikte motor in Suid-Afrika? AUTO24.co.za is jou plaaslike mark vir die koop en verkoop van kwaliteit gebruikte motors.",
    homePageKeywordsOg: "Gesertifiseerde Gebruikte Motors, Koop Motors Suid-Afrika, Verkoop Motors, Motor Mark, AUTO24 Suid-Afrika",
    buyPageTitle: "Iimoto Ezithengiswayo eNingizimu Afrika | Fumana amaxabiso aphezulu kwiimoto namhlanje - auto24.co.za",
    buyPageDesc: "Verken 'n wye reeks gesertifiseerde gebruikte motors by AUTO24.co.za. Vind jou ideale motor maklik en vinnig.",
    buyCarKeywords: "Koop Motors, Gesertifiseerde Gebruikte Motors, Kwaliteit Motors, Bekostigbare Motors",
    buyPageTitleOg: "Iimoto Ezithengiswayo eNingizimu Afrika | Fumana amaxabiso aphezulu kwiimoto namhlanje - auto24.co.za",
    buyPageDescOg: "Verken 'n wye reeks gesertifiseerde gebruikte motors by AUTO24.co.za. Vind jou ideale motor maklik en vinnig.",
    buyCarKeywordsOg: "Koop Motors, Gesertifiseerde Gebruikte Motors, Kwaliteit Motors, Bekostigbare Motors",
    sellPageTitle: "Thengisa Imoto Yakho Ngokukhawuleza & Ngokuqhagamshelana Kalula | Fumana Izicelo Zomxhelo Ngqo Namhlanje - auto24.co.za",
    sellPageDesc: "Ufuna ukuthengisa imoto yakho? Fumana isikhokelo semali ngokukhawuleza ku-auto24.co.za! Ngendlela esheshayo nefanelekileyo, ungathengisa imoto yakho namhlanje kwaye ufumane imali ngokukhawuleza. Sukuba ulinde—funda ixabiso lemoto yakho ngoku!",
    sellPageKeywords: "Verkoop Motors, Gebruikte Motor Verkope, Vinnige Verkope, Motor Evaluering",
    sellPageTitleOg: "Thengisa Imoto Yakho Ngokukhawuleza & Ngokuqhagamshelana Kalula | Fumana Izicelo Zomxhelo Ngqo Namhlanje - auto24.co.za",
    sellPageDescOg: "Ufuna ukuthengisa imoto yakho? Fumana isikhokelo semali ngokukhawuleza ku-auto24.co.za! Ngendlela esheshayo nefanelekileyo, ungathengisa imoto yakho namhlanje kwaye ufumane imali ngokukhawuleza. Sukuba ulinde—funda ixabiso lemoto yakho ngoku!",
    sellPageKeywordOg: "Verkoop Motors, Gebruikte Motor Verkope, Vinnige Verkope, Motor Evaluering",
    aboutPageTitle: "Oor AUTO24.co.za - Jou Betroubare Plaaslike Gebruikte Motor Mark",
    aboutPageDesc: "Leer meer oor AUTO24.co.za, Suid-Afrika se voorste gesertifiseerde gebruikte motor mark.",
    aboutPageKeywords: "Oor AUTO24, Gesertifiseerde Gebruikte Motor Mark, Betroubare Motor Verkope",
    aboutPageTitleOg: "Oor AUTO24.co.za - Jou Betroubare Plaaslike Gebruikte Motor Mark",
    aboutPageDescOg: "Leer meer oor AUTO24.co.za, Suid-Afrika se voorste gesertifiseerde gebruikte motor mark.",
    aboutPageKeywordsOg: "Oor AUTO24, Gesertifiseerde Gebruikte Motor Mark, Betroubare Motor Verkope",
    financePageTitle: "Iimali Zemoto Zenziwe Zilula | 100% Elinezinga Eliphakathi kwezeMali - auto24.co.za",
    financePageDesc: "Phonononga ukufumana imali yemoto ngaphandle kwemingeni kunye ne- Auto24.co.za - 100% kwi-intanethi, amaxabiso aphakathi kunye nemigomo enokuguquguquka. Fumana imoto yakho yomphupha namhlanje!",
    financePageKeywords: "Motor Finansiering, AUTO24 Finansiering, Maklike Finansiering",
    financePageTitleOg: "Iimali Zemoto Zenziwe Zilula | 100% Elinezinga Eliphakathi kwezeMali - auto24.co.za",
    financePageDescOg: "Phonononga ukufumana imali yemoto ngaphandle kwemingeni kunye ne- Auto24.co.za - 100% kwi-intanethi, amaxabiso aphakathi kunye nemigomo enokuguquguquka. Fumana imoto yakho yomphupha namhlanje!",
    financePageKeywordOg: "Motor Finansiering, AUTO24 Finansiering, Maklike Finansiering",
    insurePageTitle: "Qinisekisa Umshwalense Weemoto, Ukuvikeleka Okungenangxaki KwiXabiso Eliphezulu - auto24.co.za",
    insurePageDesc: "Fumana umshwalense wemoto ophumelelayo ngexabiso elihle ku-auto24.co.za. Sinikezela ngokuvikeleka okungenangxaki okuhlangabezana nezidingo zakho—vikele imoto yakho namhlanje!",
    insurePageKeywords: "Motor Versekering, AUTO24 Versekering, Voertuig Dekking",
    insurePageTitleOg: "Qinisekisa Umshwalense Weemoto, Ukuvikeleka Okungenangxaki KwiXabiso Eliphezulu - auto24.co.za",
    insurePageDescOg: "Fumana umshwalense wemoto ophumelelayo ngexabiso elihle ku-auto24.co.za. Sinikezela ngokuvikeleka okungenangxaki okuhlangabezana nezidingo zakho—vikele imoto yakho namhlanje!",
    insurePageKeywordOg: "Motor Versekering, AUTO24 Versekering, Voertuig Dekking",
    blogPageTitle: "Iingcebiso Zokugcinwa Kweemoto kunye nezeMveliso Zokuvelisa Iimoto - auto24.co.za",
    blogPageDesc: "Vakashela auto24.co.za ukufumana iingcebiso ezivela kubachwephesha zokugcinwa kweemoto, iimeko zezimakethe kunye neendaba zezeMveliso. Hlala uphambili ekugcinweni kweemoto kunye nophuculo lwezithuthi!",
    blogPageKeywords: "AUTO24 Blog, Motor Wenke, Motor Nuus, Suid-Afrikaanse Motor Mark",
    blogPageTitleOg: "Iingcebiso Zokugcinwa Kweemoto kunye nezeMveliso Zokuvelisa Iimoto - auto24.co.za",
    blogPageDescOg: "Vakashela auto24.co.za ukufumana iingcebiso ezivela kubachwephesha zokugcinwa kweemoto, iimeko zezimakethe kunye neendaba zezeMveliso. Hlala uphambili ekugcinweni kweemoto kunye nophuculo lwezithuthi!",
    blogPageKeywordsOg: "AUTO24 Blog, Motor Wenke, Motor Nuus, Suid-Afrikaanse Motor Mark",
    requestCarPageTitle: "Fumana imoto yephupha lakho | Isicelo seMoto oyifunayo -auto24.co.za",
    requestCarPageDesc: "Submit a request, and we’ll help you find the perfect car tailored to your needs.",
    requestCarPageKeywords: "Find Car, Submit Request, Auto 24 Suid-Afrika",
    requestCarPageTitleOg: "Fumana imoto yephupha lakho | Isicelo seMoto oyifunayo -auto24.co.za",
    requestCarPageDescOg: "Submit a request, and we’ll help you find the perfect car tailored to your needs.",
    requestCarPageKeywordsOg: "Find Car, Submit Request, Auto 24 Suid-Afrika",
    qNaPageTitle: "AUTO24- Imibuzo ebuzwa qho",
    contactUsPageTitle: "AUTO24- Qhagamshelana nathi",
    privacyPolicyPageTitle: "AUTO24- Umthetho wabucala",
    termsNusePageTitle: "AUTO24- Imimmiselo nemiqathango",
    carDetailPageTitle: "Thenga {{make}}, {{price}} ZAR - AUTO24",
    carDetailPageDesc: "Thenga {{make}} imoto esetyenzisiweyo eqinisekisiweyo kwi {{PRIX}} ZAR nge-AUTO24, eMzantsi Afrika",
    buyPageDescWhenBrand: "Amaxabiso angcono kunye neenkonzo ze {{BRAND}} iimoto ezisetyenzisiweyo eMzantsi Afrika. Ipolisi yembuyekezo,iwaranti, i-inshurensi,ukugcinwa kunye nazo zonke zibandakanyiwe!",
    buyCarTitle: "Koop Gesertifiseerde Kwaliteit Gebruikte Motors in Suid-Afrika | AUTO24.co.za",
    buyCarDesc: "Verken 'n wye reeks gesertifiseerde gebruikte motors by AUTO24.co.za. Vind jou ideale motor maklik en vinnig.",
    carFinanceFormPageTitle:
        `Fumana ikhwelela yakho yemoto ethengiselwe ngu-AUTO24.co.za`,
    carFinanceFormPageDesc:
        `Fumana izicelo ezimbalwa zokufumana ikhwelela yakho yemoto ethengiselwe ngu-AUTO24.co.za, uqinisekise ukuba uxhumane nabasolwa bethu xa ufuna usizo! Isicelo esiqinile!`,
    carFinanceFormPageTitleOg:
        `Fumana ikhwelela yakho yemoto ethengiselwe ngu-AUTO24.co.za`,
    carFinanceFormPageDescOg:
        `Fumana izicelo ezimbalwa zokufumana ikhwelela yakho yemoto ethengiselwe ngu-AUTO24.co.za, uqinisekise ukuba uxhumane nabasolwa bethu xa ufuna usizo! Isicelo esiqinile!`,
}

export default metaXhosa;